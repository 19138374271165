import React, { useState } from 'react';
import {
  Box,
  Code,
  Button,
    Stack,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,   
    Center,
    StatGroup,
    HStack,
    Text,
    Divider
} from '@chakra-ui/react';
import dayjs from 'dayjs';

function Stats(props) {
    const { sortType, dateRange, filteredLogs, endpoint, employerNo, employers, showErrors, timestampOnly  } = props;
    // stats
    const totalLogs = filteredLogs.length;
    const totalErrors = filteredLogs.filter(a => !Number(a.status) ).length;
    const totalSuccess = filteredLogs.filter(a => Number(a.status) ).length;
    // get pcts
    const pctErrors = Math.round((totalErrors / totalLogs) * 100);
    const pctSuccess = Math.round((totalSuccess / totalLogs) * 100);

    return (
        <Box>
            <Text fontSize='lg'>Filter Overview</Text>
            <Text fontSize='sm'>
                {/* show date range */}
                <Badge colorScheme="purple">Date Range:</Badge> {dayjs.unix(dateRange[0]).format('DD/MM/YYYY')} - {dayjs.unix(dateRange[1]).format('DD/MM/YYYY')}
            </Text>
            <Text fontSize='sm'>
                <Badge colorScheme="purple">Endpoint:</Badge> {endpoint.toString().length ? endpoint : 'All Endpoints'}
            </Text>
            <Box mt={1}>
                <HStack spacing='24px'>
                    <Text fontSize='sm'>
                        <Badge colorScheme="purple">Employer Number:</Badge> {employerNo.toString().length ? employerNo : 'All Employers'}
                    </Text>
                    {
                        employerNo.toString().length
                            ?   <Text fontSize='sm'>
                                    <Badge colorScheme="purple">Trade Name:</Badge> {employers.find(a => a.employerNo === employerNo)?.tradeName || 'Unavailable'}
                                </Text>
                            :   null
                    }
                </HStack>
                
            </Box>
            <Text fontSize='sm' mt={1}>
                <Badge colorScheme="purple">Error types:</Badge> { Number(showErrors) ? Number(showErrors) === 1 ? 'All Errors' : 'Critical Errors' : 'All Logs'}
            </Text>
            <Text fontSize='sm' mt={1}>
                <Badge colorScheme="purple">Sorting:</Badge> { Number(sortType) === 1 ? 'Most Recent First' : 'Oldest First'}
            </Text>
            {/* <Text fontSize='sm' mt={1}>
                <Badge colorScheme="purple">Logs with timestamps only:</Badge> {Number(timestampOnly) ? 'Yes' : 'No'}
            </Text> */}
            <Center height='20px'>
                <Divider />
            </Center>
            <StatGroup>
                <Stat>
                    <StatLabel>Total Logs</StatLabel>
                    <StatNumber>{ totalLogs}</StatNumber>
                </Stat>

                <Stat>
                    <StatLabel>Success</StatLabel>
                    <StatNumber>{ totalSuccess }</StatNumber>
                    <StatHelpText>
                    Pct. { pctSuccess }%
                    </StatHelpText>
                </Stat>

                <Stat>
                    <StatLabel>Fail</StatLabel>
                    <StatNumber>{totalErrors}</StatNumber>
                    <StatHelpText>
                    Pct. { pctErrors }%
                    </StatHelpText>
                </Stat>
            </StatGroup>
        </Box>
    )
}

export default Stats;