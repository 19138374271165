import React, { useState } from 'react';
import {
  Box,
  Code,
  Button,
} from '@chakra-ui/react';
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Badge, Text, Heading, Divider } from '@chakra-ui/react';

function CodeRender(props) {
    const [showHide, setShowHide] = useState(0);

    const { req_res, obfuscateStatus, limit } = props;

    // trim
    const req_res_trimmed = Number(obfuscateStatus)
        ?   'Obfuscated Response'
        :   req_res.length > 200
            ?   req_res.substring(0, limit) + '...'
            :   req_res;
    
    const showHideButtonDisplay = Number(obfuscateStatus)
        ?   0
        :   req_res.length > limit
            ?   1
            :   0;  

    
    return (
        <Text>
            {/* add toggle to show and hide json */}

            <Button display={showHideButtonDisplay ? 'block' : 'none'} colorScheme='green' size='xs' mb={1} mt={1} onClick={ () => setShowHide(!showHide) }>{ showHide ? 'Hide' : 'Show' } Full JSON</Button>
            <Code style={{ whiteSpace: 'pre-wrap' }}>{ showHide ? req_res : req_res_trimmed }</Code>
        </Text>
    )
}

export default CodeRender;