import { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import dayjs from 'dayjs';


function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { setLoginStatus } = props;
  

  const emailCompare = "ssc@averly.com.na";
  const passwordCompare = "gbtGsnbPosAd!VgiHnk^";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
  
    if (email === emailCompare && password === passwordCompare) {
      setLoginStatus(1);
    } else {
      setError('Invalid email or password');
    }
  
    setIsLoading(false);
  };
  

  return (
    <Box
      bg="gray.100"
      minH="100vh"
      py="12"
      px={{
        base: '4',
        lg: '8',
      }}
    >
      <Box maxW="md" mx="auto" w={{ base: 'full', md: 'md' }} bg="white" p="8" borderWidth="1px" borderRadius="lg" boxShadow="lg">
        <Box textAlign="center">
          <Text fontSize="3xl" fontWeight="bold">
            Login
          </Text>
        </Box>
        <Box my="4" textAlign="left">
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mt="4" isRequired>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            {error && (
              <Text mt="4" color="red.500">
                {error}
              </Text>
            )}
            <Button mt="8" colorScheme="blue" isLoading={isLoading} type="submit">
              Login
            </Button>
          </form>
        </Box>
        {/* set year with dayjs */}
        <Text textAlign="center" fontSize="14px" mt={10}>Copyright {dayjs().format("YYYY")} Averly. CX SS . SSC Logs</Text>
      </Box>
    </Box>
  );
}
export default Login;
