export const addRequestStatus = (a) => {
    if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/paycon'
        && Number(a.statusCode) === 201
        && a.res.Status === 'Success'
      ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'SMS API'
        && Number(a.statusCode) === 202
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/token'
        && Number(a.statusCode) === 200
        && a.res.access_token.toString().length
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/branches'
        && Number(a.statusCode) === 200
        && a.res && Array.isArray(a.res) && a.res.length
    ){
        a.status = 1;
        return a;
    }
    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/checkStatus'
        && Number(a.statusCode) === 200
        && a.res && Object.keys(a.res).length && a.res.goodStanding
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/Employerecfcodes'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/ecfGS'
        && Number(a.statusCode) === 200
        && a.res && Array.isArray(a.res) && a.res.length
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/msdGS'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/getCustomerBalanceGS'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeCountGS'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeListGS'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/kioskLoginRequest'
        && Number(a.statusCode) === 200
        && a.sscStatus === 'SUCCESS'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ar/sl/F10OutStandingSub'
        && Number(a.statusCode) === 201
        && a.sscStatus === 'Success'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/submit'
        && Number(a.statusCode) === 201
        && a.res.Status === 'Success'
    ){
        a.status = 1;
        return a;
    }

    else if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/eeterm'
        && Number(a.statusCode) === 201
        && a.sscStatus === 'Success'
    ){
        a.status = 1;
        return a;
    }

    a.status = 0;
    return a;
};

export const addEmployerNo = (a) => {
    let employerNo = '';
    if( a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/paycon' ) {
        employerNo = a.req.data.ssc_number;
    }
    else if( a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ar/sl/F10OutStandingSub' ) {
        employerNo = a.req.data.creator.loyer;
    }
    else if( a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/eeterm' ) {
        employerNo = a.req.data.applicant.employerno;
    }
    else if(
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/checkStatus' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/Employerecfcodes' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/ecfGS' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/msdGS' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/getCustomerBalanceGS' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeCountGS' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeListGS' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/submit' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/eeterm' 
        || a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/kioskLoginRequest' 
    ) {
        employerNo = a.req.data.loyer;
    }

    a.employerNo = employerNo;
    return a;
};

export const abfoscateResponse = (a) => {
    let obfuscateStatus = 0;
    if( 
        a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/token' 
        || a.req.url === 'SMS API'
    ) {
        obfuscateStatus = 1;
    }

    a.obfuscateStatus = obfuscateStatus;
    return a;
};

export const addFriendlyName = (a, namedUrls) => {
    const url = namedUrls.find(b=>b.url===a.req.url)?.friendlyName || a.req.url;
    a.friendlyName = url;
    return a;
};

export const criticalErrorCheck = (a, logs) => {
    if( a.status === 1 ) {
        a.criticalErrorStatus = 0;
        a.criticalError = {};
        return a;
    }

    // check misc url or client specific url
    const miscUrls = [
        "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/branches",
        "SMS API",
    ];

    const miscUrlStatus = miscUrls.includes(a.req.url) ? 1 : 0;

    // if createdStatus is true, then get all logs that have the same employerNo, endpoint, created older than a.created field and a status of 1
    // if createdStatus is false, then get all logs that have the same employerNo, endpoint, no created field and a status of 1
    const otherLogs = miscUrlStatus
    ?   logs.filter(b => b.req.url === a.req.url && b.id !== a.id && b.created && b.created < a.created && Number(b.status) === 1)
    :   logs.filter(b => b.employerNo === a.employerNo && b.req.url === a.req.url && b.id !== a.id &&b.created < a.created && Number(b.status) === 1)

    // if otherLogs is not empty, then set criticalErrorStatus to 1
    const criticalErrorStatus = otherLogs.length ? 1 : 0;
    const criticalError = criticalErrorStatus ? otherLogs : {};

    a.criticalErrorStatus = criticalErrorStatus;
    a.criticalError = criticalError;

    return a;

}
    