import React from 'react';
import {
  Box,
  FormControl,
FormLabel,
Switch
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Stack,
  Badge,
  Text,
} from '@chakra-ui/react';

function Endpoints(props) {
  const { logs, endpoint, useNamedUrls, namedUrls, functions } = props;
  const { setTabIndex, setEndpoint, setShowErrors, setPage, setEmployerNo, setUseNamedUrls } = functions;



  // use logs to return the data req from logs
  const endpoints = logs.map(a => a.req.url);
  // remove duplicates
  const uniqueEndpoints = [...new Set(endpoints)];

  // count all failed requests per endpoint by looping through endpoints
  const failedRequests = {};
  for (let i = 0; i < endpoints.length; i++) {
    const endpoint = endpoints[i];
    if( !failedRequests[endpoint] ) failedRequests[endpoint] = 0;
    if( logs[i].res.statusCode !== 200 ) failedRequests[endpoint]++;
  }

  // create table to display them
  const tableRows = uniqueEndpoints.map((a, index) => {
    const url = useNamedUrls ? namedUrls.find(b=>b.url===a)?.friendlyName : a;
    const total = logs.filter(b => b.req.url === a).length;
    const success = logs.filter(b => b.req.url === a && b.status).length;
    const failure = logs.filter(b => b.req.url === a && !b.status).length;
    const critical = logs.filter(b => b.req.url === a && !b.status && b.criticalErrorStatus).length;

    const pctSuccess = (success / total) * 100;
    const pctFailure = (failure / total) * 100;
    const pctCritical = (critical / total) * 100;

    return (
      <Tr key={index}>
        <Td>{index+1}</Td>
        <Td>{url}</Td>
        <Td>
        <Text cursor="pointer" onClick={() => { setTabIndex(0); setEndpoint(a); setEmployerNo(''); setShowErrors(0); setPage(1) }}>{total}</Text>
        </Td>
        <Td>
        <Text color="blue">{success} ({pctSuccess.toFixed(2)}%)</Text>
        </Td>
        <Td>
          <Text cursor="pointer" color="red" onClick={() => { setTabIndex(0); setEmployerNo(''); setEndpoint(a); setShowErrors(1); setPage(1) }}>{failure} ({pctFailure.toFixed(2)}%)</Text>
        </Td>
        <Td>
          <Text cursor="pointer" color="red" onClick={() => { setTabIndex(0); setEmployerNo(''); setEndpoint(a); setShowErrors(2); setPage(1) }}>{critical} ({pctCritical.toFixed(2)}%)</Text>
        </Td>
      </Tr>
    )
  });

  return (
    <Box fontSize={14}>
      <Box pt={10} pb={10}>
        <Stack direction='row'>
        <FormControl display='flex' alignItems='center' mt={8}>
                <FormLabel htmlFor='' mb='0'>
                    Show friendly names for URLs?
                </FormLabel>
                <Switch size="md" isChecked={Number(useNamedUrls)} onChange={(e) => setUseNamedUrls(e.target.checked) } />
            </FormControl>
          {
            endpoint.length
            ? <Badge>All Endpoints</Badge>
            : <Badge colorScheme='green'>{endpoint}</Badge>
          }
        </Stack>
      </Box>
      <Table variant="striped" colorScheme="teal">
        <TableCaption>Endpoints</TableCaption>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Endpoint</Th>
            <Th>Total</Th>
            <Th>Success</Th>
            <Th>Failure</Th>
            <Th>Critical</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableRows}
        </Tbody>
        <Tfoot>
        <Tr>
            <Th>#</Th>
            <Th>Endpoint</Th>
            <Th>Total</Th>
            <Th>Success</Th>
            <Th>Failure</Th>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  )
}

export default Endpoints;
