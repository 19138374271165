import React, { useState, useEffect } from 'react';
import {
    Box,
    Code,
    Button,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,   
    StatArrow,
    StatGroup,
    Input,
    Select,
    Text,
    Center,
    Divider,
    Switch,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import { HStack, } from '@chakra-ui/react'
import _ from 'lodash';
import dayjs from 'dayjs';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './Filter.css';

import Stats from './Stats';

function FilterForm(props) {
    const { endpoint, endpoints, employers, timestampOnly, showErrors, employerNo, useNamedUrls, functions } = props;
    const { dateRange, page, recordsPerPage, filteredLogs, sortType } = props;
    const { setEndpoint, setTabIndex, setShowErrors, setEmployerNo, setUseNamedUrls, setFilteredLogs, setPage, setDateRange, setSortType } = functions;

    // clone employers with lodash
    const employersClone = [...employers];
    // sort employers by tradeName
    employersClone.sort((a, b) => a.tradeName.localeCompare(b.tradeName));

    useEffect(() => {

        let filteredLogs = _.cloneDeep(props.logs);
        // filter logs
        if (endpoint && endpoint.toString().length) {
            filteredLogs = filteredLogs.filter(a => a.req.url === endpoint);
        }
        if (employerNo && employerNo.toString().length) {
            filteredLogs = filteredLogs.filter(a => a.employerNo === employerNo);
        }
        if (showErrors && Number(showErrors)) {
            filteredLogs = Number(showErrors) === 1
            ?   filteredLogs.filter(a => !Number(a.status) && !Number(a.criticalErrorStatus))
            :   filteredLogs.filter(a => !Number(a.status) && Number(a.criticalErrorStatus));
        }
        // filter date range
        filteredLogs = filteredLogs.filter(a => Number(a.created) >= dateRange[0] && Number(a.created) <= dateRange[1]);
        // sort by created field most recent first. 1 => most recent first, -1 => oldest first
        filteredLogs = filteredLogs.sort((a, b) =>
            Number(sortType) === 1 ? b.created - a.created : a.created - b.created
        );
        
        setFilteredLogs(filteredLogs);
        
    }, [endpoint, employerNo, showErrors, page, recordsPerPage, dateRange, sortType, props.logs, setFilteredLogs]);

    const handleDateRangeUpdate = (e) => {
        console.log(e);
        // convert to unix timestamp
        const start = dayjs(e[0]).unix();
        const end = dayjs(e[1]).unix();
        setDateRange([start, end]);
        setTabIndex(0);
        setPage(1);
    };

    return (
        // add light blue background to the box, add light blue border to the box

        <Box p={5} fontSize={14} borderWidth={1} borderColor='#ececec' borderRadius={5} mt={10} >
            <Text fontSize='lg'>Filter logs</Text>
            <Center height='20px'>
                <Divider />
            </Center>
            <HStack spacing='24px'>
                <Box>
                    <Text fontSize={14}>Endpoint</Text>
                    <Select size='md' value={endpoint} onChange={ (e) => { setEndpoint(e.target.value); setTabIndex(0); setPage(1);  } }>
                        <option value=''>Show all endpoints</option>
                        { endpoints.map((a, index) => <option key={index} value={a}>{a}</option>) }
                    </Select>
                </Box>
                <Box>
                    <Text fontSize={14}>Employer</Text>
                    <Select size='md' value={employerNo} onChange={ (e) => { setEmployerNo(e.target.value); setTabIndex(0); setPage(1);  } }>
                        <option value=''>Show all employers</option>
                        { employersClone.map((a, index) => <option key={index} value={a.employerNo}>{a.tradeName}</option>) }
                    </Select>
                </Box>
            </HStack>
            <HStack spacing='24px' mt={5}>
                <Box>
                    <Text fontSize={14}>Log types</Text>
                    <Select size='md' value={showErrors} onChange={ (e) => { setShowErrors(e.target.value); setTabIndex(0); setPage(1);  } }>
                        <option value={0}>Show all logs</option>
                        <option value={1}>Show error logs</option>
                        <option value={2}>Show critical logs</option>
                    </Select>
                </Box>
                <Box>
                    <Text fontSize={14}>Date range</Text>
                    <DateRangePicker
                        clearIcon={null}
                        format="dd-MM-y"
                        onChange={handleDateRangeUpdate}
                        value={[new Date(dayjs.unix(dateRange[0]).format('YYYY-MM-DD')), new Date(dayjs.unix(dateRange[1]).format('YYYY-MM-DD'))]}
                    />
                </Box>
                <Box>
                    <Text fontSize={14}>Sort by</Text>
                    <Select size='md' value={sortType} onChange={ (e) => { setSortType(e.target.value); setTabIndex(0); setPage(1);  } }>
                        <option value={1}>Most Recent First</option>
                        <option value={-1}>Oldest First</option>
                    </Select>
                </Box>
            </HStack>
            <Center height='50px'>
                <Divider />
            </Center>
            {/* <FormControl display='flex' alignItems='center' mt={8}>
                <FormLabel htmlFor='timestamps' mb='0'>
                    Show only logs with timestamps?
                </FormLabel>
                <Switch id='timestamps' size="md" value={timestampOnly} onChange={ ()=> { setTimestampOnly(!timestampOnly); setTabIndex(0); setPage(1);  }} />
            </FormControl> */}
            <FormControl display='flex' alignItems='center' mt={8}>
                <FormLabel htmlFor='' mb='0'>
                    Show friendly names for URLs?
                </FormLabel>
                <Switch size="md" isChecked={Number(useNamedUrls)} onChange={(e) => setUseNamedUrls(e.target.checked) } />
            </FormControl>

            <Center height='50px'>
                <Divider />
            </Center>

            <Stats sortType={sortType} dateRange={dateRange} filteredLogs={filteredLogs} endpoint={endpoint} employerNo={employerNo} employers={employers} showErrors={showErrors} timestampOnly={timestampOnly} />
        </Box>
    )
}

export default FilterForm;