import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Center, Divider, Box, Heading, Text, Badge, HStack, Icon } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

import CodeRender from './CodeRender';
import PrevSuccess from './PrevSuccess';

function LogCard({ a, i, page, recordsPerPage, url, employerTradeName, setEmployerNo, request, responseStringified }) {

    return (
        <Card key={i} mb={3} bg={ Number(a.status) ? 'green.50' : Number(a.criticalErrorStatus) ? 'red.50' : 'orange.50'  }>
            <CardHeader>
                <Text fontSize='sm'>
                    <Badge colorScheme="purple">#:</Badge> {i+1+((page-1)*recordsPerPage)}
                </Text>
                <Text fontSize='sm'>
                    <Badge colorScheme="purple">ID:</Badge> {a.id}
                </Text>
                {
                    a.employerNo && a.employerNo.toString().length
                    ?   <Box mt={1}>
                                <HStack spacing='24px'>
                                    <Text fontSize='sm'>
                                        <Badge colorScheme="purple">Employer Number:</Badge> { a.employerNo }
                                    </Text>
                                    <Badge colorScheme="purple">Trade Name:</Badge> 
                                    {employerTradeName
                                    ?   <Text color="blue.400" cursor="pointer" onClick={() => setEmployerNo(a.employerNo) }>{employerTradeName}</Text>
                                    :   <Text fontSize='sm'>Unavailable</Text>}
                                </HStack>
                            </Box>
                    :   null
                }
            </CardHeader>
            <Center height='20px'>
                <Divider />
            </Center>
            <CardBody>  
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Request
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        <Badge>{a.req.method.toUpperCase()}</Badge> {url}
                    </Text>
                    <CodeRender req_res={ request } limit={200} />
                </Box>
                <Center height='20px'>
                    <Divider />
                </Center>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Response { a.status ? <Badge colorScheme="green">Success</Badge> : <Badge colorScheme="red">Failed</Badge>}
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        <Badge>{a.statusCode}</Badge> {a.sscStatus}
                    </Text>
                    <CodeRender req_res={ responseStringified } obfuscateStatus={a.obfuscateStatus} limit={200}  />
                </Box>
                {
                    a.criticalErrorStatus
                    ?   <PrevSuccess a={a} />
                    :   null
                }
            </CardBody>
            <Center height='20px'>
                <Divider />
            </Center>
            <CardFooter>
                <Text fontSize='sm'>
                    <Icon as={TimeIcon} boxSize={4} color="blue.400" mr={1} />
                    {a.created ? dayjs.unix(a.created).format('DD-MM-YYYY HH:mm:ss a') : 'Timestamp Unavailable'}
                </Text>
            </CardFooter>
        </Card>
    );
}

export default LogCard;