import {
  Stack,
  Text,
  Select,
  Button,
} from '@chakra-ui/react';

function Pagination(props) {
    const { setPage, setRecordsPerPage } = props;
    const { logsLength, page, recordsPerPage } = props;
    return (
        <Stack direction='row' spacing={2} mb={5} justifyContent='flex-end'>
            <Text size='sm' lineHeight="30px">
                Page: {page} of {Math.ceil(logsLength / recordsPerPage)}
            </Text>
            <Button colorScheme='blue' size='sm' onClick={() => setPage(page - 1)} isDisabled={page === 1 ? true : false}>Back</Button>
            <Button colorScheme='blue' size='sm' onClick={() => setPage(page + 1)} isDisabled={page * recordsPerPage >= logsLength || page >= Math.ceil(logsLength / recordsPerPage) ? true : false}>Next</Button>
            <Select size='sm' width={40} value={recordsPerPage} onChange={(e)=> {  setPage(1); setRecordsPerPage(e.target.value); }}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
            </Select>
        </Stack>
    )
}

export default Pagination;