import React, { useState, useEffect } from 'react';
import {
    Box,
    Badge,
    Center,
    Divider,
    Heading,
    Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button
} from '@chakra-ui/react';
import { HStack, } from '@chakra-ui/react'
import dayjs from 'dayjs';

import Stats from './Stats';
import LogsDrawer from './LogsDrawer';


function FilterForm(props) {
    const [showDrawer, setShowDrawer] = useState(false);
    const { a } = props;

    return (
        <>
            <Center height='20px'>
                <Divider />
            </Center>
                <Box>
                <Heading size='xs' textTransform='uppercase'>
                    Previous Success <Badge colorScheme="green">{a.criticalError.length} Logs</Badge>
                </Heading>
                <Button colorScheme='green' size='xs' mb={1} mt={1} onClick={ () => setShowDrawer(1) }>Show previous successful requests</Button>
                {
                    showDrawer
                    ?   <LogsDrawer a={a} setShowDrawer={setShowDrawer} />
                    :   null
                }
            </Box>
        </>
    )
}

export default FilterForm;