import React, { useState } from 'react';
import {
  Box,
} from '@chakra-ui/react';

function Employers() {
    return (
        <Box>
            Employers
        </Box>
    );
}

export default Employers;
