import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';

import Pagination from './logs/Pagination';
import LogCard from './logs/LogCard';

function Log(props) {
    const { page, recordsPerPage, employers, useNamedUrls, functions } = props;
    const { setEmployerNo, setPage, setRecordsPerPage} = functions;
    
    const filteredLogs = _.cloneDeep(props.filteredLogs);
    const slicedLogs = filteredLogs.slice((page - 1) * recordsPerPage, page * recordsPerPage);
    if( !slicedLogs.length ) return <Box>No logs found</Box>;

    const logCards = slicedLogs.length
        ?   slicedLogs.map((a, i) => {
                // if error is in the 200s status code, then use res else use res.data
                const response = Number(a.statusCode) === 0
                    ?   a.res
                    :   a.statusCode >= 200 && a.statusCode < 300
                        ?   a.res
                            :   a.res.data;
                // add string replace, add a space after every comma
                const request = a.req.data ? JSON.stringify(a.req.data).replace(/,/g, ', ') : '';
                const responseStringified = Number(a.statusCode) === 0
                    ?   response
                    :   response
                        ?   JSON.stringify(response).replace(/,/g, ', ')
                        :   '';
                // get employer tradeName
                const employerTradeName =  employers.find(b => b.employerNo === a.employerNo)?.tradeName
                const url = useNamedUrls ? a.friendlyName : a.req.url;
            return (
                // add a light green background to the card if the status is 1 and a light red if its 0
                <LogCard key={i} a={a} i={i} page={page} recordsPerPage={recordsPerPage} url={url} employerTradeName={employerTradeName} setEmployerNo={setEmployerNo} request={request} responseStringified={responseStringified} />
            )
            })
        :   <Box>No logs found</Box>

    return (
        <Box fontSize={14}>
            <Text fontSize="30px">Results: </Text>
            <Pagination page={page} recordsPerPage={recordsPerPage} logsLength={filteredLogs.length} setPage={setPage} setRecordsPerPage={setRecordsPerPage} />
            {logCards}
            <Pagination page={page} recordsPerPage={recordsPerPage} logsLength={filteredLogs.length} setPage={setPage} setRecordsPerPage={setRecordsPerPage} />
        </Box>
    )
}

export default Log;
