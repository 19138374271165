import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Center,
  Divider
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { addRequestStatus, addEmployerNo, abfoscateResponse, addFriendlyName, criticalErrorCheck } from './Helpers';
import logs from './logs-filtered.json'
import dayjs from 'dayjs';

// import pages
import Endpoints from './pages/Endpoints';
import Employers from './pages/Employers';
import Logs from './pages/Logs';

import FilterForm from './pages/logs/FilterForm';
import Login from './pages/Login';

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [employerNo, setEmployerNo] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [showErrors, setShowErrors] = useState(0);
  const [timestampOnly, setTimestampOnly] = useState(0);
  const [isLoggedin, setLoginStatus] = useState(0);
  const [useNamedUrls, setUseNamedUrls] = useState(0);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(6, 'day').startOf('day').unix(),
    dayjs().endOf('day').unix()
  ]); 
  // sort by created field most recent first. 1 => most recent first, -1 => oldest first
  const [sortType, setSortType] = useState(1);

  // loop through logs and find all available endpoints and create an array of them and save it to state
  const endpointsArray = [...new Set(logs.map(log => log.req.url))];
  // remove duplicates
  const uniqueEndpoints = [...new Set(endpointsArray)];
  // save endpoints to state
  const [endpoints, setEndpoints] = useState(uniqueEndpoints);

  const namedUrls = [
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/kioskLoginRequest",
      friendlyName: "Submit: Employer Login"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/Employerecfcodes",
      friendlyName: "Fetch: Employer ECF Codes"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/checkStatus",
      friendlyName: "Fetch: Goodstanding Statuses"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/branches",
      friendlyName: "Fetch: SSC Branches"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/getCustomerBalanceGS",
      friendlyName: "Fetch: Employer Balance"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/msdGS",
      friendlyName: "Fetch: MSD"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/ecfGS",
      friendlyName: "Fetch: ECF"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeListGS",
      friendlyName: "Fetch: Employee List"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/employeeCountGS",
      friendlyName: "Fetch: Employee Count"
    },
    {
      url: "SMS API",
      friendlyName: "Submit: SMS"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/submit",
      friendlyName: "Submit: GSC Submission"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/paycon",
      friendlyName: "Submit: Payment Confirmation"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ar/sl/F10OutStandingSub",
      friendlyName: "Submit: F10 Outstanding Submission"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/eeterm",
      friendlyName: "Submit: Employee Termination"
    },
    {
      url: "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/token",
      friendlyName: "Fetch: Kiosk Login Token"
    }
  ];

  // add more custom fields to the logs
  let updatedLogs = logs.map( a => {
    const b = addRequestStatus(a);
    const c = addEmployerNo(b);
    const d = abfoscateResponse(c);
    const e = addFriendlyName(d, namedUrls);
    return e;
  });
  // add more custom fields to the logs
  updatedLogs = logs.map( a => {
    const f = criticalErrorCheck(a, logs);
    return f;
  });

  // if timestampOnly is true, then remove all logs that do not contain the created field
  if (timestampOnly) {
    updatedLogs = updatedLogs.filter(a => a.created);
    // sort logs by created field most recent first
    updatedLogs.sort((a, b) => new Date(b.created) - new Date(a.created));
  }

  // get all successful kioskLoginRequests, these contain employerNos that are valid
  const kioskLoginRequests = updatedLogs.filter(a => a.req.url === 'https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/gs/kioskLoginRequest' && a.status);
  // get all employerNos from kioskLoginRequests
  const employerNosArray = [...new Set(kioskLoginRequests.map(log => log.employerNo))];
  // remove duplicates
  const uniqueEmployerNos = [...new Set(employerNosArray)];
  const employersArray = uniqueEmployerNos.map(a => {
    // find employer name in kioskLoginRequests
    const kioskLoginRequest = kioskLoginRequests.find(b => b.employerNo === a);
    return {
      employerNo: a,
      tradeName: kioskLoginRequest.res.tradeName,
    }
  });
  // save employer numbers to state
  const [employers, setEmployerNos] = useState(employersArray);

  return (
    <ChakraProvider theme={theme}>
      {
      isLoggedin
      ? (<Box maxW="1200px" marginX="auto" textAlign="left" fontSize="xl" mb={40}>
          <h1>SSC SS Logs</h1>
          {/* add logout */}
          {/* float to right */}
          <Text fontSize="sm">This is a simple app to view logs from the SSC SS API</Text>

          <Box mt={15}>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Logs</Tab>
                <Tab>Endpoints</Tab>
                <Tab color="red.400" onClick={() => setLoginStatus(0)}>Logout</Tab>
              </TabList>

              
              <TabPanels>
                <TabPanel>
                  <FilterForm sortType={sortType} dateRange={dateRange} page={page} recordsPerPage={recordsPerPage} logs={logs} endpoint={endpoint}  useNamedUrls={useNamedUrls} filteredLogs={filteredLogs} employerNo={employerNo} showErrors={showErrors} endpoints={endpoints} employers={employers} timestampOnly={timestampOnly} functions={{setTabIndex, setEndpoint, setShowErrors, setEmployerNo, setTimestampOnly, setUseNamedUrls, setFilteredLogs, setPage, setDateRange, setSortType }} />
                  <Logs page={page} recordsPerPage={recordsPerPage} useNamedUrls={useNamedUrls}  namedUrls={namedUrls} filteredLogs={filteredLogs} showErrors={showErrors} employers={employers} endpoint={endpoint} employerNo={employerNo} timestampOnly={timestampOnly} functions={{setTabIndex, setEmployerNo, setShowErrors, setPage, setRecordsPerPage}} />
                </TabPanel>
                <TabPanel>
                  <Endpoints page={page} recordsPerPage={recordsPerPage} useNamedUrls={useNamedUrls}  namedUrls={namedUrls} logs={updatedLogs} endpoint={endpoint} functions={{setTabIndex, setEndpoint, setShowErrors, setPage, setRecordsPerPage, setEmployerNo, setUseNamedUrls }} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
      </Box>
      )
      : <Login setLoginStatus={setLoginStatus} />
    }
    {/* add fixed footer to the bottom of the page */}
    {
      isLoggedin 
      ? <Box position="fixed" bottom="0" width="100%" bg="gray.100" p={4} fontSize="sm">
          <Text textAlign="center" fontSize="14px">Copyright {dayjs().format("YYYY")} Averly. CX SS . SSC Logs</Text>
          <Text textAlign="center" fontSize="14px">Version 1.3.15</Text>
        </Box>
      : null
    }
    </ChakraProvider>
  );
}

export default App;
