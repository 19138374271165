import dayjs from 'dayjs';
import {

    Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
    Card,
    CardHeader,
    Text,
    Badge,
    Box,
    CardBody,
    CardFooter,
} from '@chakra-ui/react';

import { Center, Divider, Heading, Icon } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import CodeRender from './CodeRender';

function LogsDrawer(props) {

    const { a, setShowDrawer } = props;
    const onClose = () => setShowDrawer(0);

    return (
      <>
        <Drawer
          isOpen={1}
          placement='right'
          onClose={onClose}
          size={'sm'}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader mt={5}>Previous Successful Request{a.criticalError.length > 1 ? "s" : null }</DrawerHeader>
  
            <DrawerBody>
              {
                a.criticalError.map((b, i) => {

                  const response = Number(b.statusCode) === 0
                    ?   b.res
                    :   b.statusCode >= 200 && b.statusCode < 300
                        ?   b.res
                            :   b.res.data;
                  const responseStringified = Number(b.statusCode) === 0
                      ?   response
                      :   response
                          ?   JSON.stringify(response).replace(/,/g, ', ')
                          :   '';
                  const request = b.req.data ? JSON.stringify(b.req.data).replace(/,/g, ', ') : '';

                    return (
                        <Box key={i}>
                            <Card p={2} mb={3} bg={ Number(b.status) ? 'green.50' : Number(b.criticalErrorStatus) ? 'red.50' : 'orange.50'  }>
                                <Text fontSize='sm'>
                                    {b.id}
                                </Text>
                                <Center height='20px'>
                                    <Divider />
                                </Center>
                                <Text fontSize='sm' textTransform="uppercase">
                                    Request
                                </Text>
                                <CodeRender req_res={ request } limit={50} />
                                <Center height='20px'>
                                    <Divider />
                                </Center>
                                <Text fontSize='sm' textTransform="uppercase">
                                    Response { b.status ? <Badge colorScheme="green">Success</Badge> : <Badge colorScheme="red">Failed</Badge>}
                                </Text>
                                <Text pt='2' fontSize='sm'>
                                    <Badge>{b.statusCode}</Badge> {b.sscStatus}
                                </Text>
                                <CodeRender req_res={ responseStringified } obfuscateStatus={b.obfuscateStatus} limit={50}  />

                                <Center height='20px'>
                                    <Divider />
                                </Center>
                                <CardFooter>
                                    <Text fontSize='sm'>
                                        <Icon as={TimeIcon} boxSize={4} color="blue.400" mr={1} />
                                        {b.created ? dayjs.unix(b.created).format('DD-MM-YYYY HH:mm:ss a') : 'Timestamp Unavailable'}
                                    </Text>
                                </CardFooter>
                            </Card>
                        </Box>
                    )
                })
              }
            </DrawerBody>
  
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  export default LogsDrawer;